<script>
export default {
    name: 'ChatGPTButton.vue',
    computed: {
        outerClass(){
            let base = "p-button p-button-gpt ";
            if (this.label === "-") {
                base += " p-button-icon-only ";
            }
            return base + this.btnClass;
        }
    },
    props: {
        label: {
            type: String,
            default(){
                return "Chiedi a ChatGPT"
            }
        },
        loading: {
            type: Boolean,
            default(){
                return false;
            }
        },
        btnClass: {
          type: String,
          default(){
              return "";
          }
        }
    }
};
</script>

<template>
    <button :class="outerClass" type="button" :disable="loading" >
        <span class="pi pi-gpt p-button-icon" :class='{"pi-spin": loading, "no-margin": label === "-"}'></span>
        <span v-if="label !== '-'" class="p-button-label">{{label}}</span>
    </button>
</template>

<style scoped lang='scss'>
    .pi-gpt {
        margin-right: 4px;
        &.no-margin{
            margin-right: 0;
        }
        &:before {
            content: url(/assets/layout/images/gpt_logo.svg);
            width: 20px;
            height: 20px;
            display: block;
        }
    }
    .p-button.p-button-gpt{
        background: #74aa9c;
        border-color: #74aa9c;
        padding-left: 6px;
        &.p-button-icon-only{
            padding-left: 0;
        }
    }
    .p-button.p-button-gpt:enabled:hover{
        background: #60998BFF;
        border-color: #60998BFF;
    }
</style>
