import tools from '../../../libs/tools';
import { minLength, required, url } from '@vuelidate/validators';
const mustHaveId = (value) => {
  if (!value) return false;
  if (!value.id) return false;
  return true;
};

const model = {
    id: "",
    s_cats: "",
    s_aut: "",
    categories: [],
    authors: [],
    tags: [],
    slug: "",
    last_update: 0,
    original_title: "",
    translations: {}
};
const translation = {
    alt: "",
    audio: {
        url: "",
        seconds: 0,
    },
    catchline: "",
    chapters: [],
    cover_url: "",
    file: "",
    free: false,
    isbn: "",
    pages: 0,
    pub_at : null,
    publish_state: "",
    publisher: {
        id: "",
    },
    copywriter_id: null,
    speaker_id: null,
    purpose: "",
    slug: "",
    store_info: {
        name: "",
        url: "",
    },
    last_update: 0,
    subtitle: "",
    summary: "",
    title: "",
    year: "",
    keywords: [],
};



export default {
    ...tools.models.apiCommons(model, translation, "book_id"),
    modelValidation: {
        model: {
            slug: {
                required,
                slug: tools.models.validators.SLUG
            },
            authors: {
                required,
                minLength: minLength(1)
            },
            tags: {
                required,
                minLength: minLength(1)
            },
            categories: {
                required,
                minLength: minLength(1)
            }
        }
    },
    translationValidation: {
        model: {
            slug: {
                required,
                slug: tools.models.validators.SLUG
            },
            catchline: { required },
            purpose: { required },
            publish_state: { required },
            cover_url: {
                required,
                url
            },
            year: {
                required
            },
            publisher: {
                mustHaveId
            },
            title: { required },
            pub_at: {
                validDate: tools.models.validators.PUBLISH_DATE
            }
        }
    },
    uploads: [
        { el: "uCoverUrl", target: "cover_url", folder: "/images/%Y/%M", protect: false, maxNum: 1, types: [tools.models.uploadFileTypes.IMAGE]},
        { el: "uAudioUrl", target: "audio.url", folder: "/audio/%L/%Y/%M", protect: true, urlChanger: url => url.replace('/audio/','/'), maxNum: 1, types: [tools.models.uploadFileTypes.SOUND]},
        { el: "uFile", target: "file", folder: "/books/%L/%Y/%M", protect: false, maxNum: 1, types: [tools.models.uploadFileTypes.PDF]},
    ]

};
