<template>
    <div>
        <h5 class='mt-3'>Modifica il prompt di ChatGPT</h5>
        <div class='grid p-fluid formgrid mt-3'>
            <div class='col-12 field'>{{ value['chatGPTFixedPrompt'] }}</div>
        </div>
        <div class='grid p-fluid formgrid mt-3'>
            <div class='col-12 field'>
                <span class='p-float-label'>
                    <Textarea rows='3' v-model='value["chatGPTPrompt"]'></Textarea>
                    <label>Prompt</label>
                </span>
            </div>
        </div>
        <div class='grid p-fluid formgrid mt-3'>
            <div class='col-2 field '>
                <span class='p-float-label'>
                    <InputNumber  min='0' max='2' v-model='value["chatGPTTemp"]' />
                    <label>Temp</label>
                </span>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data(){
        return {};
    },
    props: ['modelValue'],
    emits: ['update:modelValue'],
    computed: {
        value: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        }
    }
}
</script>
