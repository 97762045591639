<template>
    <div v-if='model && !model.is_null' :key='"abstracts_t_"+lang'>
        <Dialog v-model:visible="GptDKeywords" modal header="Keywords suggerite" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
            <template v-for='k in chatGPTFoundKeywords' :key='k' >
                <Chip :label="k" removable class='mr-2 mb-2 p-badge-success' @remove='removeFromSuggestedKeyword(k)'/>
            </template>
            <ChatGPTEditor v-if='chatGPTTweak' v-model='chatGPTData'/>

            <template #footer>
                <ChatGPTButton  v-if='chatGPTTweak' :loading='keywordsLoading' label='Chiedi nuovamente a ChatGPT' @click='requestKeywords(true)'/>
                <InputSwitch :modelValue="chatGPTTweak" title='Migliora' class=' ml-3' @click='chatGPTTweak = !chatGPTTweak'/>
                <Button label="Aggiungile" class='p-button-success ml-3'  icon="pi pi-check" @click="addAllSuggestedKeyword" autofocus />
                <Button label="Chiudi" class='p-button-danger ml-3' icon="pi pi-times" @click="closeDialog('GptDKeywords')" />
            </template>
        </Dialog>
        <Dialog v-model:visible="GptDSummary" modal header="Sommario suggerito" :style="{ width: '100rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
            <div class='grid p-fluid formgrid mt-5'>
                <div class='field col-12 md:col-10 html-editor-wrap p-float-label'>
                    <HtmlEditor
                        v-model:content='chatGPTFoundSummary'
                        :key='"cont_book_sug_sum_"+id+"_"+lang'
                        contentType='html'/>
                    <label>Sommario suggerito</label>
                </div>
            </div>

            <ChatGPTEditor v-if='chatGPTTweak' v-model='chatGPTData'/>

            <template #footer>
                <ChatGPTButton  v-if='chatGPTTweak' :loading='summaryLoading' label='Chiedi nuovamente a ChatGPT' @click='requestSummary(true)'/>
                <InputSwitch :modelValue="chatGPTTweak" title='Migliora' class=' ml-3' @click='chatGPTTweak = !chatGPTTweak'/>
                <Button label="Accetta" class='p-button-success ml-3'  icon="pi pi-check" @click="importSummary" autofocus />
                <Button label="Chiudi" class='p-button-danger ml-3' icon="pi pi-times" @click="closeDialog('GptDSummary')" />
            </template>
        </Dialog>
        <Dialog v-model:visible="GptDCatchline" modal header="Catchline suggerita" :style="{ width: '100rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
            <div class='grid p-fluid formgrid mt-5'>
                <div class='field col-12 md:col-10 html-editor-wrap p-float-label'>
                    <span class='p-float-label'>
                        <InputText id='catchline_gpt'
                                   type='text'
                                   v-model="chatGPTFoundCatchline"
                        />
                        <label for='catchline_gpt'>Catchline</label>
                    </span>
                </div>
            </div>
            <ChatGPTEditor v-if='chatGPTTweak' v-model='chatGPTData'/>
            <template #footer>
                <ChatGPTButton  v-if='chatGPTTweak' :loading='catchLineLoading' label='Chiedi nuovamente a ChatGPT' @click='requestCatchline(true)'/>
                <InputSwitch :modelValue="chatGPTTweak" title='Migliora' class=' ml-3' @click='chatGPTTweak = !chatGPTTweak'/>
                <Button label="Accetta" class='p-button-success ml-3'  icon="pi pi-check" @click="importCatchline" autofocus />
                <Button label="Chiudi" class='p-button-danger ml-3' icon="pi pi-times" @click="closeDialog('GptDCatchline')" />
            </template>
        </Dialog>
        <div class='text-right last_update'>Data ultima modifica: {{ lastUpdateDate }}</div>

        <form @submit='save' @change='formIsDirty'>
            <div class='grid p-fluid formgrid'>
                <div class=' col-12'>
                    <Toolbar class='mt-2'>
                        <template v-slot:start>
                            <div><span class="publish_state">Stato di pubblicazione </span>
                                <Dropdown
                                    v-model="v$.model.publish_state.$model"
                                    :options="publish_states"
                                    optionLabel="label"
                                    optionValue="code"
                                    placeholder="Stato di pubblicazione" /></div>
                            <div class='ml-5'><span class="publish_state">Data di pubblicazione </span>
                                <Calendar
                                    :showTime="true"
                                    inputId="pub_at"
                                    v-model="v$.model.pub_at.$model"
                                    dateFormat="dd/mm/yy"
                                    :class="{'p-invalid': v$.model.pub_at.$invalid && v$.model.pub_at.$dirty}"
                                /></div>
                        </template>
                    </Toolbar>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-5'>
                <div class='field col-12' v-if='model.id !== 0'>
                    <Button
                        v-if='skills.length === 0'
                        label="Verifica presenza in una skill"
                        @click='onGetSkillMatchesRequest'
                        class="p-button-outlined p-button-info mr-2 p-mb-2" />
                    <ul v-else>
                        <li v-for='skill in skills' :key='"assoc_skills_"+model.id+"_"+lang+"_"+skill["skill_id"]'>
                            <router-link :to='"/app/cont/skills-v2/"+skill["skill_id"]+"/"+lang' target='_blank'>
                                Skill #{{skill["skill_id"]}} (matches: {{skill["num"]}})
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12 md:col-8'>
                    <div class="p-inputgroup">
                        <span class='p-float-label'>
                            <InputText id='t_slug'
                                       type='text'
                                       v-model="v$.model.slug.$model"
                                       :class="{'p-invalid': v$.model.slug.$invalid && v$.model.slug.$dirty}"
                            />
                            <Button label='Fix' class="p-button-warning" title='Correggi SLUG' @click='model.slug = sluggify(model.slug)'/>
                            <Button icon="pi pi-angle-double-down" class="p-button-primary" title='Eredita' @click='model.slug = row.slug'/>
                            <Button icon="pi pi-sort-up" class="p-button-info" title='Dal titolo' @click='model.slug = sluggify(model.title)'/>
                            <label for='t_slug'>Slug</label>
                        </span>
                    </div>
                    <small>URL pubblico: <a :href='getFrontendBookUrl(lang, model.slug)' target='_blank'>{{getFrontendBookUrl(lang, model.slug)}}</a></small>
                </div>
            </div>

            <!-- keywords -->
            <div class='grid p-fluid formgrid mt-3' v-if='model && model["keywords"]'>
                <div class='field col-12 md:col-8'>
                    <Card>
                        <template #title>
                            Keywords
                        </template>
                        <template #content>
                            <div class='grid p-fluid formgrid mb-3'>
                                <div class='field col-12'>
                                    <div class="p-inputgroup">
                                        <span class='p-float-label'>
                                            <InputText id='keyword'
                                                       type='text'
                                                       v-model='addingKeyword'
                                                       v-on:keyup.enter='addKeyword'
                                            />
                                            <Button label='Aggiungi' class="p-button-success" title='Aggiungi' @click='addKeyword'/>
                                            <ChatGPTButton :loading='keywordsLoading' label='Chiedi a ChatGPT' @click='requestKeywords(false)'/>
                                            <label for='keyword'>Keyword</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <template v-for='k in model["keywords"]' :key='k' >
                                <Chip v-if='k !== "-"' :label="k" removable class='mr-2 p-badge-success' @remove='removeKeyword(k)'/>
                                <span v-else>* le keywords verranno cancellate tutte dopo il salvataggio</span>
                            </template>
                        </template>
                    </Card>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-2 md:col-1'>
                    <Dropdown
                        v-model='model.free'
                        :options='binary'
                        optionLabel='label'
                        optionValue='value'
                        placeholder='Free?' />
                    <label class='sup'>FREE?</label>
                </div>

                <div class='field col-10  md:col-7'>
                    <span class='p-float-label'>
                        <InputText id='title'
                                   type='text'
                                   v-model="v$.model.title.$model"
                                   :class="{'p-invalid': v$.model.title.$errors.length}"
                        />
                        <label for='title'>Titolo</label>
                    </span>
                </div>
            </div>

            <div class='grid p-fluid formgrid'>
                <div class='field col-12  md:col-8'>
                    <span class='p-float-label'>
                        <InputText id='subtitle'
                                   type='text'
                                   v-model="model.subtitle"
                        />
                        <label for='subtitle'>Sottotitolo</label>
                    </span>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12  md:col-9'>
                    <div class="p-inputgroup">
                        <span class='p-float-label'>
                            <InputText id='catchline'
                                       type='text'
                                       v-model="v$.model.catchline.$model"
                                       :class="{'p-invalid': v$.model.catchline.$errors.length}"
                            />
                            <ChatGPTButton :loading='catchLineLoading' label='Chiedi a ChatGPT' @click='requestCatchline'/>
                        <label for='catchline'>Catchline</label>
                    </span>
                    </div>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12 md:col-10 html-editor-wrap p-float-label'>
                    <HtmlEditor
                        v-model:content='model.summary'
                        :key='"cont_book_sum_"+id+"_"+lang'
                        contentType='html'/>
                    <label>Sommario</label>
                </div>
            </div>
            <div class='grid'>
                <div class='col-12 md:col-10 text-center'>
                    <ChatGPTButton :loading='summaryLoading' label='Chiedi summary a ChatGPT' @click='requestSummary(false)'/>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12 md:col-10 html-editor-wrap p-float-label'>
                    <HtmlEditor
                        v-model:content="model.purpose"
                        :key='"cont_book_pur_"+id+"_"+lang'
                        contentType='html'/>
                    <label>Purpose</label>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-6'>
                <div class='field col-12 md:col-4'>
                    <uppy-dashboard
                        :uppy='uCoverUrl'
                        :props='uppyDashboardProps'
                    />
                </div>
                <div class='field col-12 md:col-6'>
                    <span class='p-float-label'>
                        <InputText id='cover_url'
                                   type='text'
                                   :class="{'p-invalid': v$.model.cover_url.$errors.length}"
                                   v-model="v$.model.cover_url.$model"
                        />
                        <label for='cover_url'>Cover</label>
                    </span>
                    <div v-if='model.cover_url' class='text-center mt-3'>
                        <img :src='model.cover_url' alt='Model Image' :height='uppyDashboardProps.height-40'/>
                    </div>
                </div>
            </div>

            <div class='grid p-fluid formgrid'>
                <div class='field col-12  md:col-8'>
                    <span class='p-float-label'>
                        <InputText id='alt'
                                   type='text'
                                   v-model="model.alt"
                        />
                        <label for='alt'>Didascalia immagine</label>
                    </span>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-6'>
                <div class='field col-12 md:col-4'>
                    <uppy-dashboard
                        :uppy='uFile'
                        :props='uppyDashboardProps'
                    />
                </div>

                <div class='field col-12 md:col-6'>
                    <span class='p-float-label'>
                        <InputText id='file'
                                   type='text'
                                   v-model="model.file"
                        />
                        <label for='cover_url'>File PDF</label>
                    </span>
                    <div v-if='model.file' class='text-center mt-3'>
                        <a :href='model.file' target='_blank'>
                            <font-awesome-icon
                                class='text-color danger pointer'
                                title='Preview'
                                :style='{marginTop: "15px"}'
                                :icon="['far', 'file-pdf']"
                                size='6x'
                            />
                        </a>
                    </div>
                    <div class='mt-3'>
                        <router-link :to='"/previews/books/pdf/"+id+"/"+lang' target='_blank'>
                            <Button
                                label='Genera'
                                class='p-button-info'></Button>
                        </router-link>
                    </div>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-6'>
                <div class='field col-12 md:col-4'>
                    <uppy-dashboard
                        :uppy='uAudioUrl'
                        :props='uppyDashboardProps'
                    />
                </div>
                <div class='field col-12 md:col-6'>
                    <span class='p-float-label'>
                        <InputText id='uAudioUrl'
                                   type='text'
                                   v-model="model.audio.url"
                        />
                        <label for='uAudioUrl'>Audio</label>
                    </span>
                    <div class='grid p-fluid formgrid mt-5'>
                        <div class='field col-6  md:col-3'>
                            <span class='p-float-label'>
                                <InputNumber id='audio_seconds' mode='decimal' :useGrouping='false'
                                           v-model="model.audio.seconds"
                                />
                                <label for='audio_seconds'>Durata</label>
                            </span>
                        </div>
                    </div>
                    <div v-if='model.audio.url' class='text-center mt-3'>
                        <video controls name='audiourl' :style='{height: "50px", width:"400px"}'>
                            <source type='audio/mpeg' :src='openUrl'>
                        </video>
                    </div>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12  md:col-3'>
                    <div class='p-inputgroup'>
                        <Button :icon="publishersRefreshLoadingIcon" class="p-button-warning" @click='refreshPublishers'/>
                        <span class='p-float-label'>
                        <Dropdown v-model="v$.model.publisher.$model"
                                  id='publisher'
                                  dataKey="id"
                                  :options="dd_publishers"
                                  optionLabel="label"
                                  :class="{'p-invalid': v$.model.publisher.$errors.length}"
                                  :filter="true"
                                  filterPlaceholder="Cerca"/>
                        <label for='publisher'>Casa Editrice</label>
                    </span>
                    </div>
                </div>

                <div class='field col-12  md:col-3'>
                    <span class='p-float-label'>
                        <InputText id='store_name'
                                   type='text'
                                   v-model="model.store_info.name"
                        />
                        <label for='store_name'>Online Store</label>
                    </span>
                </div>

                <div class='field col-12  md:col-4'>
                    <span class='p-float-label'>
                        <InputText id='store_url'
                                   type='text'
                                   v-model="model.store_info.url"
                        />
                        <label for='store_url'>Store URL</label>
                    </span>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-2 '>
                    <span class='p-float-label'>
                        <InputNumber id='pages' mode='decimal' :useGrouping='false'
                                   type='text'
                                   v-model="model.pages"
                        />
                        <label for='pages'>Pagine</label>
                    </span>
                </div>

                <div class='field col-2 '>
                    <span class='p-float-label'>
                        <InputText id='year'
                                   type='text'
                                   v-model="v$.model.year.$model"
                                   :class="{'p-invalid': v$.model.year.$errors.length}"
                        />
                        <label for='year'>Anno</label>
                    </span>
                </div>

                <div class='field col-4 '>
                    <span class='p-float-label'>
                        <InputText id='isbn'
                                   type='text'
                                   v-model="model.isbn"
                        />
                        <label for='isbn'>ISBN</label>
                    </span>

                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12  md:col-3'>
                    <div class='p-inputgroup'>
                        <Button :icon="speakersRefreshLoadingIcon" class="p-button-warning" @click='refreshSpeakers'/>
                        <span class='p-float-label'>
                        <Dropdown v-model="model.speaker_id"
                                  id='speaker_id'
                                  dataKey="id"
                                  :options="dd_speakers"
                                  optionLabel="label"
                                  option-value='id'
                                  :filter="true"
                                  filterPlaceholder="Cerca"/>
                        <label for='speaker_id'>Speaker 4books</label>
                    </span>
                    </div>
                </div>
                <div class='field col-12  md:col-3'>
                    <div class='p-inputgroup'>
                        <Button :icon="copywritersRefreshLoadingIcon" class="p-button-warning" @click='refreshCopywriters'/>
                        <span class='p-float-label'>
                            <Dropdown v-model="model.copywriter_id"
                                      id='copywriter_id'
                                      dataKey="id"
                                      :options="dd_copywriters"
                                      optionLabel="label"
                                      option-value='id'
                                      :filter="true"
                                      filterPlaceholder="Cerca"/>
                            <label for='copywriter_id'>Copywriter 4books</label>
                        </span>
                    </div>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <Panel :toggleable='true' class='col-12 sidebar-like'>
                    <template #header>
                        Capitoli
                    </template>
                    <ChaptersManager v-if='model["last_update"] > 0' v-model='model.chapters'/>
                    <span v-else>Salva la traduzione per accedere alla gestione dei capitoli</span>
                </Panel>
            </div>

            <div class='grid p-justify-center mt-4'>
                <div class='col-12 text-center'>
                    <SaveButton @click='save' :load='loading'/>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import ChatGPTButton from '../../../components/4books/ChatGPTButton.vue';
import Navigations from '../../../mixins/navigations';
import Notifications from '../../../mixins/notifications';
import Uploader from '../../../mixins/uploader';
import DropdownPublishers from '../../../mixins/dd_publishers';
import DropdownSpeakers from '../../../mixins/dd_speakers';
import DropdownCopywriters from '../../../mixins/dd_copywriters';

import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers('Cont/Books');
const commonStore = createNamespacedHelpers('Common');

import useVuelidate from '@vuelidate/core'
import ChaptersManager from './chapters/ChaptersManager';

import tools from '../../../libs/tools';
import model from './model';
import ChatGPTEditor from '../../../components/4books/ChatGPTEditor.vue';
export default {
    data(){
        return {
            chatGPTFoundKeywords : [],
            chatGPTFoundSummary: "",
            chatGPTFoundCatchline: "",
            chatGPTData: {
                chatGPTTemp: 0,
                chatGPTFixedPrompt: "",
                chatGPTPrompt: "",
            },
            chatGPTTweak: false,
            GptDKeywords: false,
            GptDSummary: false,
            GptDCatchline: false,
            Barcode: null,
            addingKeyword: "",
            skills: [],
            binary: [
                { value: false, label: 'No' },
                { value: true, label: 'Yes' },
            ]
        };
    },
    components: {
        ChaptersManager, ChatGPTButton, ChatGPTEditor
    },
    setup () {
        return { v$: useVuelidate() }
    },
    validations () {
        return model.translationValidation;
    },
    beforeUnmount() {
        this.resetUploads(model.uploads);
    },
    computed: {
        ...mapGetters(['row', 'loading', 'keywordsLoading', 'summaryLoading', 'catchLineLoading']),
        ...commonStore.mapGetters(['languages', 'publish_states']),
        openUrl(){
            if (!this.model.audio || !this.model.audio.url) return '';
            return this.model.audio.url.replace(process.env.VUE_APP_CLOUDFRONT_PROTECTED, process.env.VUE_APP_CLOUDFRONT_OPEN + '/audio');
        }
    },
    watch: {
        row(n){
            this.initModel(n);
        },
        lang(){
            this.initModel(this.row);
        },
        dirty(n){
            if (n) this.formIsDirty();
            else this.formIsClean();
        },
        'model.lastUpload'(n){
            if (!n) return;
            if (this.model.repo) this.model.repo += "|";
            this.model.repo += n;
        }
    },
    mounted() {
        this.initModel(this.row);
        this.prepareUploads(model.uploads, this.lang);
    },
    methods: {
        ...mapMutations(['formIsDirty', 'formIsClean']),
        ...mapActions(['saveTranslation', 'findSkillsAssociations', 'searchKeywords', 'searchSummary', 'searchCatchline']),
        closeDialog(which){
            this[which] = false;
            this.chatGPTData = {
                chatGPTTemp: 0,
                chatGPTFixedPrompt: "",
                chatGPTPrompt: ""
            };
            this.chatGPTTweak = false;
        },
        async save () {
            await this.v$.$validate();
            if (this.v$.model.$invalid) {
                this.ShowError("Errore", "Alcuni campi del form non sono corretti");
                return
            }

            this.saveTranslation({
                data: model.formatTranslation(this.model),
                parent_id: model.translationParentId(this.model)
            }).then(() => {
                this.formIsClean();
                this.ShowSuccess('Ottimo', 'Traduzione salvata con successo');
            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        assignPromptResponse(res){
            this.chatGPTData = {
                chatGPTTemp: res['temp'],
                chatGPTFixedPrompt: res['fixed_prompt'],
                chatGPTPrompt: res['prompt']
            };
        },
        requestKeywords(tweaked = false){
            this.chatGPTFoundKeywords = [];
            if (!this.model || !this.model['book_id']) {
                this.ShowError("Questa traduzione ancora non esiste!")
                return;
            }
            const payload = {
                book_id: this.model['book_id'],
                lang: this.lang
            };
            if (this.chatGPTData['chatGPTPrompt'] && tweaked){
                payload['prompt'] = this.chatGPTData['chatGPTPrompt'];
                payload['temperature'] = this.chatGPTData['chatGPTTemp'];
            }
            this.searchKeywords(payload).then(res => {
                if (res['keywords'] && res['keywords'].length){
                    this.chatGPTFoundKeywords = res['keywords'];
                    this.assignPromptResponse(res);
                    this.GptDKeywords = true;
                } else {
                    this.ShowWarning("ChatGPT non ha fornito suggerimenti")
                }
            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        requestSummary(tweaked = false){
            this.chatGPTFoundSummary = "";
            if (!this.model || !this.model['book_id']) {
                this.ShowError("Questa traduzione ancora non esiste!")
                return;
            }
            const payload = {
                book_id: this.model['book_id'],
                lang: this.lang
            };
            if (this.chatGPTData['chatGPTPrompt'] && tweaked){
                payload['prompt'] = this.chatGPTData['chatGPTPrompt'];
                payload['temperature'] = this.chatGPTData['chatGPTTemp'];
            }
            this.searchSummary(payload).then(res => {
                if (res['content'].length){
                    this.chatGPTFoundSummary = res['content'];
                    this.assignPromptResponse(res);
                    this.GptDSummary = true;
                } else {
                    this.ShowWarning("ChatGPT non ha fornito suggerimenti")
                }
            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        requestCatchline(tweaked = false){
            this.chatGPTFoundCatchline = "";
            if (!this.model || !this.model['book_id']) {
                this.ShowError("Questa traduzione ancora non esiste!")
                return;
            }
            const payload = {
                book_id: this.model['book_id'],
                lang: this.lang
            };
            if (this.chatGPTData['chatGPTPrompt'] && tweaked){
                payload['prompt'] = this.chatGPTData['chatGPTPrompt'];
                payload['temperature'] = this.chatGPTData['chatGPTTemp'];
            }
            this.searchCatchline(payload).then(res => {
                if (res['content'].length){
                    this.chatGPTFoundCatchline = res['content'];
                    this.assignPromptResponse(res);
                    this.GptDCatchline = true;
                } else {
                    this.ShowWarning("ChatGPT non ha fornito suggerimenti")
                }
            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        onGetSkillMatchesRequest(){
            this.findSkillsAssociations({
                books: [model.translationParentId(this.model)],
                lang: this.lang
            }).then(res => {
                if (res.length === 0){
                    this.ShowWarning('Nessun match', 'Questo libro non è presente in alcuna skill');
                    return;
                }
                this.skills = res;
            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        removeKeyword(k){
            this.model["keywords"] = this.model["keywords"].filter(el => el !== k);
            if (this.model["keywords"].length === 0) {
                this.model["keywords"].push("-");
            }
        },
        removeFromSuggestedKeyword(k){
            this.chatGPTFoundKeywords = this.chatGPTFoundKeywords.filter(el => el !== k);
        },
        addSuggestedKeyword(kw){
            const k = this.sluggify(kw);
            this.model["keywords"] = this.model["keywords"].filter(el => el !== "-" && el !== k); //REMOVE "-"
            this.model["keywords"].push(k)
            this.removeFromSuggestedKeyword(kw);
        },
        addAllSuggestedKeyword(){
            this.GptDKeywords = false;
            if (!this.chatGPTFoundKeywords.length) return;
            this.chatGPTFoundKeywords.forEach((k) => {
                this.addSuggestedKeyword(k);
            })
        },
        importSummary(){
            this.GptDSummary = false;
            if (!this.chatGPTFoundSummary.length) return;
            this.model['summary'] = this.chatGPTFoundSummary;
        },
        importCatchline(){
            this.GptDCatchline = false;
            if (!this.chatGPTFoundCatchline.length) return;
            this.model['catchline'] = this.chatGPTFoundCatchline;
        },
        addKeyword(){
            const k = this.sluggify(this.addingKeyword);
            this.model["keywords"] = this.model["keywords"].filter(el => el !== "-" && el !== k); //REMOVE "-"
            this.model["keywords"].push(k)
            this.addingKeyword = "";
        },

        initModel(mod){
            this.addingKeyword = "";
            this.dirty = false;
            this.skills = [];
            const base = model.newTranslation(this.id, this.lang);
            if (mod && mod.translations && mod.translations[this.lang]){
                this.model = tools.models.fillTranslation(model.parseTranslation(mod.translations[this.lang]), base);
                if (!this.model.audio){
                    this.model.audio = {
                        url: "",
                        seconds: 0
                    };
                }
                //
                if (!this.model["keywords"]){
                    this.model["keywords"] = [];
                }
                return;
            }

            this.model = base;
        }
    },
    props: {
        lang: {
            type: String,
            default: 'it'
        },
        id: {
            type: String
        }
    },
    mixins: [
        Navigations, Notifications, Uploader, DropdownPublishers, DropdownSpeakers, DropdownCopywriters
    ]
}
</script>
